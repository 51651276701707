import type {FileOverviewPayload, OverviewPayload} from '@github-ui/code-view-types'
import {useCurrentRepository} from '@github-ui/current-repository'
import {Box} from '@primer/react'
import {RecentlyTouchedBranches} from './RecentlyTouchedBranches'
import {useReposAppPayload} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'
import {InterractionLimitsBanner} from './banners/InterractionLimitsBanner'
import PendingInvitationBanner from './banners/PendingInvitationBanner'
import {ProtectBranchBanner} from './banners/ProtectBranchBanner'
// eslint-disable-next-line no-restricted-imports
import PublishBanners from '../../../../react-code-view/components/blob/Banners/PublishBanners'
import {UseActionBanner} from './banners/UseActionBanner'

export function OverviewHeader({payload}: {payload: FileOverviewPayload}) {
  const repo = useCurrentRepository()

  const {banners} = payload.overview

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      {banners.recentlyTouchedDataChannel !== null ? (
        <RecentlyTouchedBranches channel={banners.recentlyTouchedDataChannel} repo={repo} />
      ) : null}
      <OverviewBanners overview={payload.overview} />
    </Box>
  )
}

function OverviewBanners({overview}: {overview: OverviewPayload}) {
  const {
    showUseActionBanner,
    showProtectBranchBanner,
    actionId,
    actionSlug,
    publishBannersInfo: {dismissActionNoticePath, releasePath, showPublishActionBanner},
    interactionLimitBanner,
    showInvitationBanner,
    inviterName,
  } = overview.banners
  const {helpUrl} = useReposAppPayload()

  const hasBanners =
    interactionLimitBanner ||
    (showInvitationBanner && inviterName) ||
    showPublishActionBanner ||
    (showUseActionBanner && actionSlug && actionId) ||
    showProtectBranchBanner

  return (
    <Box sx={{mb: hasBanners ? 3 : 0, display: 'flex', flexDirection: 'column', rowGap: 3}}>
      {interactionLimitBanner && <InterractionLimitsBanner interactionLimitBanner={interactionLimitBanner} />}
      {showInvitationBanner && inviterName && <PendingInvitationBanner inviterName={inviterName} />}
      <PublishBanners
        showPublishActionBanner={showPublishActionBanner}
        releasePath={releasePath}
        dismissActionNoticePath={dismissActionNoticePath}
        sx={{mt: 0}}
      />
      {showUseActionBanner && actionSlug && actionId && <UseActionBanner actionSlug={actionSlug} actionId={actionId} />}
      {showProtectBranchBanner && <ProtectBranchBanner helpUrl={helpUrl} />}
    </Box>
  )
}

try{ OverviewHeader.displayName ||= 'OverviewHeader' } catch {}
try{ OverviewBanners.displayName ||= 'OverviewBanners' } catch {}