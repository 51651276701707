import React, {Suspense, lazy, useCallback, useEffect, useState} from 'react'

import type {FileOverviewPayload} from '@github-ui/code-view-types'
import {useCurrentRepository} from '@github-ui/current-repository'
import {branchCountPath, branchesPath, repositoryTreePath, tagCountPath, tagsPath} from '@github-ui/paths'
import {ErrorBoundary} from '@github-ui/react-core/error-boundary'
import {Link} from '@github-ui/react-core/link'
import {SafeHTMLBox} from '@github-ui/safe-html'
import {ScreenSize} from '@github-ui/screen-size'
import {useNavigate} from '@github-ui/use-navigate'
import {verifiedFetch} from '@github-ui/verified-fetch'
import {GitBranchIcon, KebabHorizontalIcon, PlusIcon, SearchIcon, TagIcon, UploadIcon} from '@primer/octicons-react'
import {ActionList, ActionMenu, Box, Button, IconButton, Octicon} from '@primer/react'
import {AllShortcutsEnabled} from '@github-ui/code-view-shared/components/AllShortcutsEnabled'
// eslint-disable-next-line no-restricted-imports
import {CodeViewError} from '../../../react-code-view/components/CodeViewError'
// eslint-disable-next-line no-restricted-imports
import {
  BranchInfoBar,
  BranchInfoBarErrorBanner,
} from '../../../react-code-view/components/directory/BranchInfoBar/BranchInfoBar'
// eslint-disable-next-line no-restricted-imports
import DirectoryContent from '../../../react-code-view/components/directory/DirectoryContent'
// eslint-disable-next-line no-restricted-imports
import {FindFilesShortcut} from '../../../react-code-view/components/file-tree/FilesSearchBox'
// eslint-disable-next-line no-restricted-imports
import {ReposHeaderRefSelector} from '../../../react-code-view/components/headers/ReposHeaderRefSelector'
// eslint-disable-next-line no-restricted-imports
import {AddFileDropdownButton} from '../../../react-code-view/components/headers/header-components/AddFileDropdownButton'
import {useCurrentUser} from '../../../react-shared/Repos/CurrentUser'
import {CodeButton} from './CodeButton'
import {OverviewFiles} from './OverviewRepoFiles'
import {OverviewHeader} from './header/OverviewHeader'
import BranchRenamePopover from './popovers/BranchRenamePopover'
import ParentBranchRenamePopover from './popovers/ParentBranchRenamePopover'
import {useFilesPageInfo} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'

const FileResultsList = lazy(() => import('../../../react-code-view/components/file-tree/FileResultsList'))

export default function OverviewContent({payload}: {payload: FileOverviewPayload}) {
  const repo = useCurrentRepository()
  const currentUser = useCurrentUser()
  const {refInfo, path} = useFilesPageInfo()
  const navigate = useNavigate()

  const [branchCount, setBranchCount] = useState('')
  const [tagCount, setTagCount] = useState('')

  const overview = payload.overview

  useEffect(() => {
    const fetchBranchCount = async () => {
      const response = await verifiedFetch(branchCountPath(repo), {method: 'get'})
      if (response.ok) {
        const text = await response.text()
        setBranchCount(text)
      }
    }
    const fetchTagCount = async () => {
      const response = await verifiedFetch(tagCountPath(repo), {method: 'get'})
      if (response.ok) {
        const text = await response.text()
        setTagCount(text)
      }
    }
    if (overview) {
      fetchBranchCount()
      fetchTagCount()
    }
  }, [overview, repo])

  const onFindFilesShortcut = React.useCallback(() => {
    if (window.innerWidth < ScreenSize.xlarge) {
      navigate(`${window.location.pathname}?search=1`)
    }
  }, [navigate])

  const navigateToSearch = useCallback(() => {
    navigate(`${window.location.pathname}?search=1`)
  }, [navigate])

  return (
    <>
      {payload.error ? (
        <CodeViewError {...payload.error} />
      ) : (
        <Box sx={{my: 3}}>
          <OverviewHeader payload={payload} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1,
              pb: 3,
              pt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                '@media screen and (max-width: 320px)': {flexGrow: 1},
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  '@media screen and (max-width: 380px)': {
                    '.ref-selector-button-text-container': {
                      maxWidth: '80px',
                    },
                  },
                  '@media screen and (max-width: 320px)': {
                    flexGrow: 1,
                    '.overview-ref-selector': {
                      width: '100%',
                      '> span': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        '> span[data-component="text"]': {flexGrow: 1},
                      },
                    },
                  },
                }}
              >
                <ReposHeaderRefSelector buttonClassName="overview-ref-selector" />
                {overview.popovers.rename ? (
                  <BranchRenamePopover rename={overview.popovers.rename} />
                ) : overview.popovers.renamedParentRepo ? (
                  <ParentBranchRenamePopover
                    branchName={overview.popovers.renamedParentRepo.branchName}
                    nameWithOwner={overview.popovers.renamedParentRepo.nameWithOwner}
                  />
                ) : null}
              </Box>
              <Box sx={{display: 'flex', '@media screen and (max-width: 1079px)': {display: 'none'}}}>
                <Button
                  as="a"
                  leadingVisual={GitBranchIcon}
                  variant="invisible"
                  href={branchesPath({repo})}
                  sx={{color: 'fg.muted', px: 1, 'span[data-component="leadingVisual"]': {mr: '4px !important'}}}
                >
                  {branchCount ? <SafeHTMLBox unverifiedHTML={branchCount} /> : 'Branches'}
                </Button>

                <Button
                  as="a"
                  leadingVisual={TagIcon}
                  variant="invisible"
                  href={tagsPath({repo})}
                  sx={{color: 'fg.muted', px: 1, 'span[data-component="leadingVisual"]': {mr: '4px !important'}}}
                >
                  {tagCount ? <SafeHTMLBox unverifiedHTML={tagCount} /> : 'Tags'}
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  '@media screen and (min-width: 1080px)': {display: 'none'},
                  '@media screen and (max-width: 543px)': {display: 'none'},
                }}
              >
                <Button
                  as="a"
                  aria-label="Go to Branches page"
                  icon={GitBranchIcon}
                  variant="invisible"
                  href={branchesPath({repo})}
                  sx={{color: 'fg.muted'}}
                />
                <Button
                  as="a"
                  aria-label="Go to Tags page"
                  icon={TagIcon}
                  variant="invisible"
                  href={tagsPath({repo})}
                  sx={{color: 'fg.muted'}}
                />
              </Box>
            </Box>
            <Box sx={{display: 'flex', pl: 2, gap: 2}}>
              <Box sx={{display: 'flex', gap: 2, '@media screen and (max-width: 543px)': {display: 'none'}}}>
                <Box sx={{display: 'flex', '@media screen and (max-width: 1011px)': {display: 'none'}}}>
                  <Suspense fallback={null}>
                    <FileResultsList onFindFilesShortcut={onFindFilesShortcut} sx={{m: 0}} />
                  </Suspense>
                </Box>
                <Box sx={{display: 'flex', '@media screen and (min-width: 1012px)': {display: 'none'}}}>
                  <AllShortcutsEnabled>
                    <FindFilesShortcut inputRef={null} onFindFilesShortcut={onFindFilesShortcut} />
                  </AllShortcutsEnabled>
                  <Button onClick={navigateToSearch}>Go to file</Button>
                </Box>
                <div className="react-directory-add-file-icon">
                  <AddFileDropdownButton useIcon={true} />
                </div>
                <div className="react-directory-remove-file-icon">
                  <AddFileDropdownButton useIcon={false} />
                </div>
              </Box>
              <CodeButton
                repoId={repo.id}
                repoOwner={repo.ownerLogin}
                repoName={repo.name}
                refName={refInfo.name}
                payload={overview}
                isLoggedIn={!!currentUser}
                primary={!overview.templateButton}
              />
              <Box sx={{display: 'flex', '@media screen and (min-width: 544px)': {display: 'none'}}}>
                <ActionMenu>
                  <ActionMenu.Anchor>
                    <IconButton icon={KebabHorizontalIcon} aria-label="Open more actions menu" />
                  </ActionMenu.Anchor>

                  <ActionMenu.Overlay>
                    <ActionList>
                      <ActionList.LinkItem href={branchesPath({repo})}>
                        <ActionList.LeadingVisual>
                          <Octicon icon={GitBranchIcon} />
                        </ActionList.LeadingVisual>
                        Branches
                      </ActionList.LinkItem>
                      <ActionList.LinkItem href={tagsPath({repo})}>
                        <ActionList.LeadingVisual>
                          <Octicon icon={TagIcon} />
                        </ActionList.LeadingVisual>
                        Tags
                      </ActionList.LinkItem>
                      <ActionList.Divider />
                      <ActionList.Item onClick={navigateToSearch}>
                        <ActionList.LeadingVisual>
                          <Octicon icon={SearchIcon} />
                        </ActionList.LeadingVisual>
                        Go to file
                      </ActionList.Item>
                      <ActionList.LinkItem
                        as={Link}
                        to={repositoryTreePath({repo, path, commitish: refInfo.name, action: 'new'})}
                      >
                        <ActionList.LeadingVisual>
                          <Octicon icon={PlusIcon} />
                        </ActionList.LeadingVisual>
                        Create new file
                      </ActionList.LinkItem>
                      <ActionList.LinkItem
                        href={repositoryTreePath({repo, path, commitish: refInfo.name, action: 'upload'})}
                      >
                        <ActionList.LeadingVisual>
                          <Octicon icon={UploadIcon} />
                        </ActionList.LeadingVisual>
                        Upload file
                      </ActionList.LinkItem>
                    </ActionList>
                  </ActionMenu.Overlay>
                </ActionMenu>
              </Box>
            </Box>
          </Box>
          {payload.tree.showBranchInfobar && (
            <ErrorBoundary fallback={<BranchInfoBarErrorBanner />}>
              <BranchInfoBar />
            </ErrorBoundary>
          )}
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
            <DirectoryContent overview={payload.overview} />
            <OverviewFiles
              initialFiles={overview.overviewFiles}
              shouldRecommendReadme={overview.banners.shouldRecommendReadme}
              isPersonalRepo={overview.banners.isPersonalRepo}
              processingTime={overview.overviewFilesProcessingTime}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

try{ FileResultsList.displayName ||= 'FileResultsList' } catch {}
try{ OverviewContent.displayName ||= 'OverviewContent' } catch {}